import React from "react"
import { graphql } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import GrayFullWidth from "../components/GrayFullWidth"
import ButtonTemplate from "../components/buttons/ButtonTemplate"
import { GatsbyImage } from "gatsby-plugin-image"

const Departments = ({ data }) => {
  const { englishTitle, syllabicsTitle, heroImage } = data.sanityDepartmentPage
  const hero = heroImage?.asset || null

  const departments = data.allSanityDepartments.edges
  // const defaultIcon = data.file.childImageSharp

  return (
    <Layout hideAllDepartmentsButton>
      <SEO title="Departments" />
      <Hero
        englishTitle={englishTitle}
        syllabics={syllabicsTitle}
        heroImage={hero}
      />
      <div className="grid gap-y-2 my-2">
        {departments.map((department, index) => {
          const iconSide = index % 2 === 1 ? "right" : "left"
          const dept = department.node
          const {
            showInList,
            departmentName,
            syllabicsTitle,
            departmentIcon,
            departmentDescription,
            id,
            slug,
            departmentColors,
          } = dept
          const colors = ["blue", "red", "yellow"]

          {
            /* Pull in button colours from Sanity (or default to null */
          }
          const backgroundColor = departmentColors?.main

          const deptGraphic =
            departmentColors?.graphic?.asset?.gatsbyImageData || null
          const buttonColors = {
            bannerBg: departmentColors?.linkA,
            triangleFill: departmentColors?.linkB,
          }

          if (showInList) {
            return (
              <GrayFullWidth
                key={`${id}-${index}`}
                title={departmentName}
                syllabicsTitle={syllabicsTitle}
                // icon={departmentIcon ? departmentIcon.asset : defaultIcon}
                iconSide={iconSide}
                backgroundColor={backgroundColor}
                className=" overflow-hidden"
              >
                {/* <Img  fluid={fluid} /> */}
                {departmentDescription ? (
                  <p className="max-w-4xl text-base text-center md:text-lg mx-4 lg:mx-auto z-10 relative">
                    {departmentDescription}
                  </p>
                ) : null}
                <div className="grid justify-center flex-grow items-end">
                  <ButtonTemplate
                    to={`/departments/${slug.current}`}
                    text="Learn More"
                    className="z-10 relative text-white justify-self-center"
                    {...buttonColors}
                  />
                </div>
                <div className="absolute w-full h-full top-0 flex items-center justify-center lg:justify-end opacity-40 z-0">
                  <div className=" h-full  w-5/6 md:w-3/6  xl:w-5/12 xl:pr-20 flex items-center">
                    <GatsbyImage
                      image={deptGraphic}
                      className="w-full"
                      alt={departmentName || ""}
                    />
                  </div>
                </div>
              </GrayFullWidth>
            )
          } else {
            return null
          }
        })}
      </div>
    </Layout>
  )
}

export default Departments

export const query = graphql`
  query DepartmentsPageQuery {
    sanityDepartmentPage {
      englishTitle
      syllabicsTitle
      heroImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
    allSanityDepartments(sort: { fields: departmentName, order: ASC }) {
      edges {
        node {
          showInList
          departmentName
          slug {
            current
          }
          departmentIcon {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
            altText
          }
          id
          departmentDescription
          syllabicsTitle
          departmentColors {
            main
            linkA
            linkB
            graphic {
              asset {
                gatsbyImageData(
                  width: 500
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "icons/Blank-Icon.png" }) {
      id
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`
