import React from "react"

// Icons
import Administration from "../images/icons/Admin-Icon.png"
import Communities from "../images/icons/Communities-Icon.png"
import Departments from "../images/icons/Departments-Icon.png"
import Education from "../images/icons/Education-Icon.png"
import Health from "../images/icons/Health-Icon.png"
import Syllabics from "./Syllabics"

const DepartmentTitles = {
  Administration: Administration,
  Communities: Communities,
  Departments: Departments,
  Education: Education,
  Health: Health,
}

const GrayFullWidth = ({
  title = "Needs Title",
  // icon = Health,
  iconSide = "left",
  children,
  syllabicsTitle = "",
  className = "",
  backgroundColor = null,
}) => {
  return (
    <section
      className={`relative flex flex-col min-h-20rem  bg-gray-light pb-12 bg-contain bg-no-repeat bg-${iconSide} ${className}`}
      style={{ backgroundColor: `${backgroundColor || "#ededed"}` }}
    >
      <h3 className="justify-center font-heading text-center  pt-8 text-4xl relative z-10">
        {syllabicsTitle && (
          <>
            <span>{syllabicsTitle}</span>
            <br />
          </>
        )}
        {title}
      </h3>
      {children}
    </section>
  )
}
export default GrayFullWidth
